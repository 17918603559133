import Popover from '@material-ui/core/Popover'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { badgeFormatComma, dateToString } from 'common/generalUtil'
import MCAAS020 from 'pages/MCAAS020'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { setTargetUrl } from 'reducers/commonSettingsMenuReducer'
import { init, newsInit, setRecruitmentManagementDivision, signout, getUserInfo, yearChange, setTargetYearForDisplay, setServiceMode, setServiceModeForDisplay, serviceModeChange } from 'reducers/globalMenuReducer'
import { routeList } from 'routes/routes'
import { magiContants as contants, PARENT_WINDOW_NAME } from 'utils/contants'
import history from 'utils/history'
import moment from 'moment'
import { 
  setMessageReceiveListSort,
  initMessageReceiveListSearchCondition,
  setReceiveButtonActiveFlag,
  setReceiveSelectedFlag,
  setPageChangeFlag,
  setMessageReceiveListPage,} from 'reducers/messageListReducer';
import { receiveSubmitValue } from 'pages/MCAZS010/formConfig';
import { searchRecruitmentStatusReportList, setItemCodeValResult, setRefreshFlagOfReport } from 'reducers/recruitmentStatusReportReducer'
import { setTopBarFlg } from 'reducers/progressStagentAlertReducer'
import { setRefreshFlagOfInterview } from 'reducers/interviewScheduleReducer'
import logo from 'images/miws_on-navy.jpg'
import blueSmallLogo from 'images/miws_on-blue_shinsotsu_132x76.jpg'
import redSmallLogo from 'images/miws_on-red_chuuto_132x76.jpg'
import { setMCAXS010RefreshFlag } from 'reducers/applicantListReducer'
import { setMCBHS040RefreshFlag } from 'reducers/submissionRequestListReducer'
import { setMCAXS020RefreshFlag } from 'reducers/entryListReducer'
import ElasticAlert from 'pages/MCAWS010/ElasticAlert'
import { Tooltip, Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { promptUrl, getYearChangeUrl, getYearChangeUrl3,getYearChangeMessage1, specialPromptUrlType1, getMarchModeChangeMessage1, caseOfToEntryListUrl } from './handleTopBarUrl'
import Select from 'react-select'
//次期9月対応 #62669 start
import { MCAAS020NewsRequest } from 'types/MCAAS020/MCAAS020NewsRequest'
//次期9月対応 #62669 end
import { setAlerted } from 'reducers/companyLoginReducer'

// MCB ロット３　start
import {setMcbReserveCount, setMcbDraftCount} from 'reducers/messageListReducer'
import Cookies from 'js-cookie'
import { getMarchModeFromToken } from 'utils/marchModeGetUtil'
// MCB ロット３　end


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 960
  },
  aCursor: {
    cursor: 'pointer',
  },
  muiPaperRoot: {
    '& .MuiPaper-root': {
      top: '75px!important',
      right:8
    },
  },
  transForm:{
    transform: 'none !important',
    marginLeft: '24px !important',
    backgroundColor: '#d14500',
    borderRadius: '50%',
    boxSizing: 'border-box',
    color: '#fff',
    display: 'block',
    fontSize: '1.4rem',
    height: '22px',
    lineHeight: '22px',
    padding: '0 2px',
    position: 'static',
    right: '2px',
    textAlign: 'center',
    textIndent: 0,
    top: '2px',
    minWidth: '22px',
  },
  titleAuto:{
    minWidth: 34,
    width: 'auto !important',
    backgroundPosition: '5px center, 5px 34px',
  },
  menuGroup: {
    '& li': {
      flexBasis: 'calc(90% / 3) !important'
    },
    minHeight: '42px!important',
  },
  settingGroup: {
    margin: '0 24px 0 12px !important',
    order: 3,
  },
  logo: {
    flexBasis: '132px!important',
    maxWidth: '132px!important',
    minWidth: '132px!important',
    overflow: 'hidden!important',
    textIndent: '100vw!important',
    whiteSpace: 'nowrap',
    backgroundColor: 'inherit!important',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  afterLogo: {
    order: 2,
    width: '100%',
  },
  afterLogoUp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '34px',
    '&::after':{
      content: "'dummy'",
      minHeight: 'inherit',
      fontSize: 0,
    },
  },
  afterLogoDown: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  companyInfo: {
    fontSize: '1.2rem!important',
    paddingRight: '50px!important',
// 次期12月対応 #65650 start
    paddingLeft: '220px!important',
// 次期12月対応 #65650 end
    // 年度対応 #6433 start
    width: '80%',
    // 年度対応 #6433 end
    textAlign: 'right',
    lineHeight: '1.2!important',
    wordBreak: 'break-all',
  },
  iconLogout: {
    backgroundPosition: 'right center',
    height: '16px',
    margin: 0,
    width:'16px',
    overflow: 'hidden',
    padding: 0,
    textDecoration: 'none',
    textIndent: '100%',
    whiteSpace: 'nowrap',
    '&:hover':{
      backgroundColor: '#1950a6!important',
      color: '#fff!important',
    },
    position: 'absolute',
    right: '25px',
    top: '8px',
  },
  switchBtn: {
    top: '8px!important',
  },
  listText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // 年度対応 start
  yearContainer: {
    position: 'fixed',
    width: '250px',
    display: 'flex',
    margin: '0px',
    marginTop: '1px!important',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontSize: 'inherit!important',
  },
  yearContent: {
    flex: '0.6',
    textAlign: 'left'
  },
  // 年度対応 end
  // 転職March #75621 START
  marchModeContent: {
    flex: '0.5',
    textAlign: 'left'
  },
  // 転職March #75621 END
}));

// 年度対応 start
// 年度プルダウンリストスタイル
const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '30px!important',
    maxHeight: '30px!important',
    cursor: 'pointer',
  }),
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
    '& input': {
      height: '20px!important',
      lineHeight: '18px!important',
    },
  }),
  container: (provided: any) => ({ ...provided, flex: '0.85' }),
  option: (provided: any, state: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
    lineHeight: '10px',
    color: state.isSelected ? 'hsl(0,0%,100%)' : 'black',
    textAlign: 'left',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    maxHeight: '30px!important',
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 'unset' })
}
// 年度対応 end

interface MenuInfo {
  companyName: string,//企業名
  fullName: string,//氏名
  newApplicantsCount: number,//新規応募者
  unreadCVSCount: number,//履歴書の未読数
  unreadMessagesCount: number,//（新卒/中途）未読のメッセージ数
  waitForScheduleCount: number,//（新卒/中途）日程確定待ちの件数
  newsCount: number,//（新卒/中途）公開されたお知らせの件数
  companyId: string,//企業ID
}
interface Props {
  menuInfo: MenuInfo
}

const TopBar = ({ menuInfo }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { companyName, fullName, newApplicantsCount, unreadCVSCount, unreadMessagesCount, waitForScheduleCount, newsCount, companyId } = menuInfo
  const targetUrl = useSelector((state: RootState) => state.commonSettingsMenu.targetUrl)
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  const pageChangeFlag = useSelector((state: RootState) => state.messageList.pageChangeFlag)
  //Tab初期選択
  const [value, setValue] = useState(0)
  //お知らせ一覧初期表示
  const [open, setOpen] = useState(false)
  const url = window.location.pathname
  const handleClose = () => {
    setOpen(false);
  };
  //10：統括:切替ボタンがあります;その他:ボタンが存在しません
  const switchExistOrNoExist = "3" === globalMenu.magiApplicationStatus ? true : false
  // 0:中途採用切替Or新卒採用切替;その他:表示しない
  const [switchIs, setswitchIs] = useState(value === 0)
  useEffect(() => {
    let target = url.split("/")[1]
    if (typeof target !== "undefined"
      && null !== target) {
      if (target === "recruitmentStatusReportTab") {
        setValue(0)
      } else if (target === "entryManageTab") {
        setValue(1)
      } else if (target === "messageTab") {
        setValue(2)
      } else if (target === "interviewAdjustTab") {
        setValue(3)
      } else if (target === "commonSettingsMenu") {
        setValue(4)
      } else if (target === "" || target === "login") {
        setValue(0)
        // 転職March #75621 START
        if (Cookies.get("recruitmentManagementDivision") === '2' && getMarchModeFromToken() == '1') {
          history.push(routeList.entryList)
        } else {
          history.push(routeList.recruitmentStatusReportTab)
        }
        // 転職March #75621 END
	  // MCBリプレース MCBHS040 START
      } else if (target === "submissionRequestManagementTab") {
        setValue(5)
	  // MCBリプレース MCBHS040 END
      } else {
        setValue(4)
      }
      //true:中途採用切替Or新卒採用切替;その他:表示しない
      if (target === "recruitmentStatusReportTab") {
        setswitchIs(true)
      } else {
        setswitchIs(false)
      }
    }
  }, [url])
  const recruitmentManagementDivision = globalMenu.recruitmentManagementDivision
  //[新卒採用]ボタン押下時
  const handleClickSwitchNewGraduate = (event: any) => {
    event.preventDefault()
    // dispatch(init({ recruitmentManagementDivision }))  //２重処理削除
    dispatch(setRecruitmentManagementDivision(contants.RECRUITMENT_MANAGEMENT_DIVISION_1))
  }
  //[中途採用]ボタン押下時
  const handleClickSwitchMidCareer = (event: any) => {
    event.preventDefault()
    // dispatch(init({ recruitmentManagementDivision }))  //２重処理削除
    dispatch(setRecruitmentManagementDivision(contants.RECRUITMENT_MANAGEMENT_DIVISION_2))
  }
  //[採用状況レポート]タブ押下時
  const handleClickRecruitStatusReport = (event: any) => {
    setValue(0)
    event.preventDefault()
    const detailInitialValues = {
      recruitmentManagementDivision: recruitmentManagementDivision, //採用管理区分
      lastLogoutTime: globalMenu.lastLogoutTime, // 最終ログアウト日時
      preLoginTime: globalMenu.previousLoginTime, // 前回ログイン日時
      lastLoginTime: globalMenu.lastLoginTime, // 最終ログイン日時 
    }
    dispatch(init({ recruitmentManagementDivision }))  //グローバルメニューの処理
    dispatch(setTopBarFlg(true))
    dispatch(searchRecruitmentStatusReportList(detailInitialValues))
    history.push(routeList.recruitmentStatusReportTab)
    dispatch(setRefreshFlagOfReport())
    dispatch(setItemCodeValResult('0'))
  }
  //[応募管理]タブ押下時
  const handleClickEntryManage = (event: any) => {
    setValue(1)
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    let pathName: string = ''
    if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
      pathName = routeList.applicantList
    }
    if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2) {
      pathName = routeList.entryList
    }
    const detailRequest = {
      request: {
        screenId: 'MCAAS010', //呼出し側機能ID
      }
    }
    const path = {
      pathname: pathName,
      state: detailRequest,
    }
    if (window.location.href.indexOf(pathName) !== -1) {
      if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
        dispatch(setMCAXS010RefreshFlag())
      }
      if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2) {
        dispatch(setMCAXS020RefreshFlag())
      }
      history.replace(path)
    }else{
      history.push(path)
    }
} 
  //[メッセージ]タブ押下時
  const handleClickMessage = (event: any) => {
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    setValue(2)
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    dispatch(setMessageReceiveListSort('0'))
    dispatch(setReceiveButtonActiveFlag(0))
    dispatch(setReceiveSelectedFlag(0))
    dispatch(setMessageReceiveListPage(1))
    dispatch(initMessageReceiveListSearchCondition ({...receiveSubmitValue, recruitmentManagementDivision: recruitmentManagementDivision}))
    if(pageChangeFlag === 0){
      dispatch(setPageChangeFlag(1))
    }else{
      dispatch(setPageChangeFlag(0))
    }
    history.push(routeList.messageInBox)
  }
  //[面接調整]タブ押下時
  const handleClickInterviewAdjust = (event: any) => {
    setValue(3)
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    if (window.location.href.indexOf(routeList.scheduledList) !== -1) {
      history.replace(routeList.scheduledList)
      dispatch(setRefreshFlagOfInterview())
    } else {
      history.push(routeList.scheduledList)
    }
  }
  
  // MCBリプレース MCBHS040 START
  //[提出リクエスト管理]タブ押下時
  const handleClickMcbManagement = (event: any) => {
    setValue(5)
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    let pathName: string = routeList.submissionRequestManagement
    const detailRequest = {
      request: {
        screenId: 'MCAAS010', //呼出し側機能ID
      }
    }
    const path = {
      pathname: pathName,
      state: detailRequest,
    }
    if (window.location.href.indexOf(routeList.submissionRequestManagement) !== -1) {
      dispatch(setMCBHS040RefreshFlag())
      history.replace(path)
    } else {
      history.push(path)
    }
  }
  // MCBリプレース MCBHS040 END
  
  //[ベル]アイコン押下時
  const handleClickBell = (event: any) => {
    dispatch(setItemCodeValResult('0'))
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    //次期9月対応 #62669 start
      let previousLoginTime = ""
      let previous : MCAAS020NewsRequest ={previousLoginTime}
      dispatch(newsInit({ previousLoginTime: previous , openFunction:setOpen }))
    //次期9月対応 #62669 end  
  }
  //[操作ログ]アイコン押下時
  const handleClickOperationLog = (event: any) => {
    event.preventDefault()
    alert("[操作ログ]アイコン")
  }

  const realUrl = useSelector(
    (state: RootState) => state.url
  )

  //[ヘルプ]アイコン(新卒採用モードの場合)押下時
  const handleClickHelp1 = (event: any) => {
    event.preventDefault()
    const w = window.open('about:blank', '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width='
      + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
    if (w !== null) {
      w.location.href = realUrl.NEW_GRADUATE_URL
    }
  }
  //[ヘルプ]アイコン(中途採用モードの場合)押下時
  const handleClickHelp2 = (event: any) => {
    event.preventDefault()
    const w = window.open('about:blank', '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width='
      + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
    if (w !== null) {
      w.location.href = realUrl.CAREER_URL
    }
  }
  //[歯車]アイコン押下時
  const handleClickCommonSettings = (event: any) => {
    setValue(4)
    event.preventDefault()
    if (targetUrl === '') {
      dispatch(setTargetUrl(window.location.pathname))
      history.push(routeList.commonSettingsMenu)
    } else {
      if (routeList.commonSettingsMenu === window.location.pathname) {
        dispatch(setTargetUrl(''))
        history.push(routeList.commonSettingsMenu)
      } else {
        history.push(routeList.commonSettingsMenu)
      }
    }
    dispatch(getUserInfo())
  }
  //ログアウトアイコン押下時
  const handleSignout = (event: any) => {
    event.preventDefault()
    const companyAccountId = globalMenu.companyAccountId
    dispatch(signout({ companyAccountId }))
    dispatch(setAlerted(""))
  }

  const max = (companyName: String | null) => {
    if (companyName !== null && companyName.length > 50) {
      return companyName.slice(0, 50) + "..."
    }
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#000000',
      maxWidth: 420,
      fontSize: 11.5,
      fontWeight: 200,
      fontFamily: 'initial',
      border: '1px solid #000000',
      borderRadius: '0px',
      wordBreak: 'break-all',
      wordWrap: 'break-word',
    },
  }))(Tooltip);

  // 年度対応 start
  // 年度確認モーダルオープンステート
  const [yearModalOpenFlg, setYearModalOpenFlg] = useState(false)
  const [year, setYear] = useState(globalMenu.targetYear)
  useEffect(() => {
    // グロナビ持つ表示の場合親画面状態をクリア
    window.opener = null
  }, [])

  // 年度切り替え
  const onYearChange = (option: any) => {
    setYear(option.value)
    // 切替なしの場合処理しない
    if (option.value == globalMenu.targetYear) {
      return
    }
    dispatch(setTargetYearForDisplay(option.value))
    // 離脱チェック系
    if (promptUrl.indexOf(history.location.pathname) < 0) {
      setYearModalOpenFlg(true)
    } else {
      setTimeout(() => {
        let confirmResult = window.confirm(getYearChangeMessage1(history.location.pathname))
        let specialCase
        if (specialPromptUrlType1.indexOf(history.location.pathname) >= 0) {
          specialCase = '1'
        }
        if (confirmResult) {
          let url = getYearChangeUrl(history.location.pathname)
          dispatch(yearChange({
            targetYear: option.value,
            url: url,
            oldTargetYear: undefined,
            specailCaseType: specialCase,
          }))
        } else {
          dispatch(setTargetYearForDisplay(globalMenu.targetYear))
        }
      }, 0)
    }
  }

  // 年度確認モーダルＯＫ
  const onYearChangeConfirmOK = () => {
    // MCB ロット３　start
    //グローバルナビで年度を変更
    if(year < contants.MCB_YEAR_2025){ 
      let url = getYearChangeUrl(history.location.pathname)
      dispatch(yearChange({
        targetYear: year,
        url: url,
        oldTargetYear: undefined,
        specailCaseType: undefined,
      }))
      setYearModalOpenFlg(false)
    }else if (year >= contants.MCB_YEAR_2025){
      let mcbUrl = getYearChangeUrl3(history.location.pathname)
      dispatch(yearChange({
        targetYear: year,
        url: mcbUrl,
        oldTargetYear: undefined,
        specailCaseType: undefined,
      }))
      setYearModalOpenFlg(false)
      }
    }
    // MCB ロット３　end

  // 年度確認モーダルキャンセル
  const onYearChangeConfirmCancel = () => {
    dispatch(setTargetYearForDisplay(globalMenu.targetYear))
    setYearModalOpenFlg(false)
  }

  // 企業名スタイル
  const getStyleOfCompanyInfo = (recruitmentManagementDivision: any, switchExistOrNoExist: any, switchIs: any) => {
    if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
      if (switchExistOrNoExist && switchIs) {
        return '395px'
      } else {
        return '275px'
      }
    } else {
      return '120px'
    }
  }
  // 年度対応 end
  const { alerted } = useSelector((state: RootState) => state.companyLogin)

  // #51347 次期開発2022年9月 start
  useEffect(() => {
    window.name = PARENT_WINDOW_NAME
  }, [])

  const handleLogoClick = (event: any) => {
    setValue(1)
    event.preventDefault()
    dispatch(init({ recruitmentManagementDivision }))
    let pathName: string = ''
    if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2) {
      pathName = routeList.entryList
    }
    const detailRequest = {
      request: {
        screenId: 'MCAAS010', //呼出し側機能ID
      }
    }
    const path = {
      pathname: pathName,
      state: detailRequest,
    }
    if (window.location.href.indexOf(pathName) !== -1) {
      if (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2) {
        dispatch(setMCAXS020RefreshFlag())
      }
      history.replace(path)
    } else {
      history.push(path)
    }
  }
  // #51347 次期開発2022年9月 end

  // 転職March #75621 START
  // Marchモードプルダウンリスト
  const [marchModeModalOpenFlg, setMarchModeModalOpenFlg] = useState(false)
  const [marchMode, setMarchMode] = useState(globalMenu.serviceMode)
  const marchModeListData = [
    { label: 'マイナビ転職', value: '0' },
    { label: 'March', value: '1' },
  ]

  // サービスモード切り替え
  const onServiceModeChange = (option: any) => {
    setMarchMode(option.value)
    // 切替なしの場合処理しない
    if (option.value == globalMenu.serviceMode) {
      return
    }
    dispatch(setServiceModeForDisplay(option.value))
    // 離脱チェック系
    if ([...promptUrl, routeList.entryInsEdit, routeList.notAdoptedNotification].indexOf(history.location.pathname) < 0) {
      setMarchModeModalOpenFlg(true)
    } else {
      setTimeout(() => {
        let confirmResult = window.confirm(getMarchModeChangeMessage1(history.location.pathname))
        if (confirmResult) {
          dispatch(serviceModeChange({
            targetService: option.value,
            specailCaseType: caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0 ? '1' : '0',
          }))
        } else {
          dispatch(setServiceModeForDisplay(globalMenu.serviceMode))
        }
      }, 0)
    }
  }
  // モード切替コントロール
  const onMarchModeChangeConfirmCancel = () => {
    dispatch(setServiceModeForDisplay(globalMenu.serviceMode))
    setMarchModeModalOpenFlg(false)
  }
  const onMarchModeChangeConfirmOK = () => {
    dispatch(serviceModeChange({
      targetService: marchMode,
      specailCaseType: caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0 ? '1' : '0',
    }))
    setMarchModeModalOpenFlg(false)
  }
  // 転職March #75621 END

  return (
    <div className={classes.root}>
      <header>
        <span className={classes.afterLogo}>
          <div className={classes.afterLogoUp}>
            <b className={classes.companyInfo}
              // 年度対応 start
              style={{
                paddingLeft: getStyleOfCompanyInfo(recruitmentManagementDivision, switchExistOrNoExist, switchIs)
              }}
              // 年度対応 end
            >
              {/* 次期12月対応 #65650 start */}
              {companyName.length > 50 ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {'MIWSID '}{companyId}：{companyName} / {fullName}
                    </React.Fragment>
                  }>
                  <span>
                    {`MIWSID ${companyId}：`}{max(companyName)} {`/ ${fullName}`}
                  </span>
                </HtmlTooltip>
              ) : (
                `MIWSID ${companyId}：${companyName} / ${fullName}`
              )}
              {/* 次期12月対応 #65650 end */}
            </b>
            <a
              href='#!'
              onClick={handleSignout}
              className={`icon-logout ${classes.iconLogout}`}
              title='MIWSからログアウトします'
              tabIndex={-1}></a>
          </div>
          <div className={classes.afterLogoDown}>
            {/* 転職March #75621 START */}
            {((recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 && globalMenu.modeInitialSuccessFlag)
             || recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1) &&
            <ul className={classes.menuGroup}>
              {!((recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                && globalMenu.serviceMode === contants.SERVICEMODE_MARCH)) &&
                <li>
                  <a href="#!"
                    onClick={handleClickRecruitStatusReport}
                    className={value === 0 ? `${"icon-header-report current"} ${classes.aCursor}` : `${"icon-header-report"} ${classes.aCursor}`}>
                    採用状況レポート
                  </a>
                </li>
              }
              <li id="myPopover" >
                <a href="#!"
                  onClick={handleClickEntryManage}
                  className={value === 1 ? `${"icon-header-management current"} ${classes.aCursor}` : `${"icon-header-management"} ${classes.aCursor}`}>
                  応募管理{
                    newApplicantsCount >= 1 && recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
                        ""
                        : ((globalMenu.serviceMode === contants.SERVICEMODE_MARCH && globalMenu.unreadCVSCountForMarch >= 1)
                          || (globalMenu.serviceMode !== contants.SERVICEMODE_MARCH && unreadCVSCount >= 1))
                          && recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 ?
                          <i>{badgeFormatComma((recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                            && globalMenu.serviceMode === contants.SERVICEMODE_MARCH) ? globalMenu.unreadCVSCountForMarch : unreadCVSCount)}</i>
                          : ""}
                  </a>
              </li>
              {!(recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
                && globalMenu.serviceMode === contants.SERVICEMODE_MARCH) &&
                <li>
                  <a href="#!"
                    onClick={handleClickMessage}
                    className={value === 2 ? `${"icon-header-message current"} ${classes.aCursor}` : `${"icon-header-message"} ${classes.aCursor}`}>
                    メッセージ{
                      unreadMessagesCount >= 1 ?
                        <i>{badgeFormatComma(unreadMessagesCount)}</i>
                        : ""}
                  </a>
                </li>
              }
              {/* [phase2] start */}
              {(recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 && globalMenu.serviceMode !== contants.SERVICEMODE_MARCH) &&
                <li>
                  <a href="#!"
                    onClick={handleClickInterviewAdjust}
                    className={value === 3 ? `${"icon-header-scheduledList current"} ${classes.aCursor}` : `${"icon-header-scheduledList"} ${classes.aCursor}`}>
                    面接調整{
                      waitForScheduleCount >= 1 ?
                        <i>{badgeFormatComma(waitForScheduleCount)}</i>
                        : ""}
                  </a>
                </li>
              }
              {/* [phase2] end */}
			  {/* MCBリプレース MCBHS040 START */}
              {recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 && 
                //  MCB ロット３　start
               globalMenu.targetYear >= contants.MCB_YEAR_2025 &&
                // MCB ロット３　end
                <li>
                  <a href="#!"
                    onClick={handleClickMcbManagement}
                    className={value === 5 ? `${"icon-header-mcb current"} ${classes.aCursor}` : `${"icon-header-mcb"} ${classes.aCursor}`}>
                    提出リクエスト管理
                  </a>
                </li>
              }
			  {/* MCBリプレース MCBHS040 END */}
            </ul>}
            {/* 転職March #75621 END */}
            <div className={classes.settingGroup}>
              <a href="#!" onClick={handleClickBell} className={`${"icon-notice"} ${classes.aCursor} ${classes.titleAuto}`} title="マイナビからのお知らせを表示します" tabIndex={-1}>
              {
                //次期9月対応 #62669 start
                  newsCount >= 1 ?
                      <i className={classes.transForm} >{badgeFormatComma(newsCount)}</i>
                      : ""
                //次期9月対応 #62669 end
                }
              </a>
              {/* <a href="#!" onClick={handleClickOperationLog} className={`${"icon-log"} ${classes.aCursor}`} title="一括操作の操作履歴を表示します" tabIndex={-1}>
        </a> */}
              <a href="#!" onClick={handleClickCommonSettings} className={`${"icon-settings"} ${classes.aCursor}`} title="共通設定メニューを表示します" tabIndex={-1}>
              </a>
              {
                recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
                  <a href="#!" onClick={handleClickHelp1} className={`${"icon-help"} ${classes.aCursor}`} title="マニュアルを表示します" tabIndex={-1}>
                  </a>
                  : recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 ?
                    <a href="#!" onClick={handleClickHelp2} className={`${"icon-help"} ${classes.aCursor}`} title="マニュアルを表示します" tabIndex={-1}>
                    </a> : ""
              }
            </div>
          </div>
        </span>
        {
          recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
            <b className={classes.logo}>
              <img src={blueSmallLogo} />
            </b>
            : recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 ?
              <b className={`Job-change ${classes.logo}`} >
                {/* #51347 次期開発2022年9月 start */}
                <img src={redSmallLogo} style={{ cursor: 'pointer' }} onClick={handleLogoClick} />
                {/* #51347 次期開発2022年9月 end */}
              </b> : <img src={logo} />
        }
        {
          switchExistOrNoExist && switchIs && recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
            <a href="#!" onClick={handleClickSwitchMidCareer} className={classes.aCursor} title="中途採用へ表示を切り替えます" tabIndex={-1}>中途採用</a>
            : (switchExistOrNoExist && switchIs && recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2) ?
              <a href="#!" onClick={handleClickSwitchNewGraduate} className={classes.aCursor} title="新卒採用へ表示を切り替えます" tabIndex={-1}>新卒採用</a>
              : ""
        }
        {/** 年度対応 start */}
        {recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
          <div className={classes.yearContainer} style={{
            marginLeft: switchExistOrNoExist && switchIs ? '275px' : '155px'
          }}>
            <span className={classes.yearContent}>{'対象年度'}</span>
            <Select
              options={globalMenu.targetYearDatas}
              styles={customSelectStyles}
              onChange={onYearChange}
              value={globalMenu.targetYearDatas.find(i => i.value == globalMenu.targetYearForDisplay)}
              placeholder={''}
              menuPortalTarget={document.body}
              noOptionsMessage={({ inputValue }) => {
                return contants.SUGGEST_NO_OPTIONS_MESSAGE
              }}
              isSearchable={false}
            />
          </div>
          // 転職March #75621 START
          : (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2
            && globalMenu.marchServiceFlag === contants.MARCHSERVICEFLAG_VALID
            && globalMenu.marchNoDisplayFlag != '1') ?
            <div className={classes.yearContainer} style={{
              marginLeft: switchExistOrNoExist && switchIs ? '275px' : '155px'
            }}>
              <span className={classes.marchModeContent}>{'サービス'}</span>
              <Select
                options={marchModeListData}
                styles={customSelectStyles}
                onChange={onServiceModeChange}
                value={marchModeListData.find(i => i.value == globalMenu.serviceModeForDisplay)}
                placeholder={''}
                menuPortalTarget={document.body}
                noOptionsMessage={({ inputValue }) => {
                  return contants.SUGGEST_NO_OPTIONS_MESSAGE
                }}
                isSearchable={false}
              />
            </div>
          // 転職March #75621 END
          : null
        }
        {/** 年度対応 end */}
      </header>
      <Popover
          className={classes.muiPaperRoot}
        open={open}
        onClose={handleClose}
        anchorEl={document.querySelector("#myPopover")}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div onMouseLeave={handleClose}>
          <MCAAS020 setOpen={setOpen} />
        </div>
      </Popover>
      { globalMenu.showFlag && !alerted ? <ElasticAlert /> : "" }
      {/** 年度対応 start */}
      <Dialog
        open={yearModalOpenFlg}
        disableBackdropClick>
        <DialogTitle>{getYearChangeMessage1(history.location.pathname)}</DialogTitle>
        <DialogActions>
          <Button onClick={onYearChangeConfirmCancel}>キャンセル</Button>
          <Button onClick={onYearChangeConfirmOK} color='primary'>OK</Button>
        </DialogActions>
      </Dialog>
      {/** 年度対応 end */}
      {/** 転職March #75621 START */}
      <Dialog
        open={marchModeModalOpenFlg}
        disableBackdropClick>
        <DialogTitle>{getMarchModeChangeMessage1(history.location.pathname)}</DialogTitle>
        <DialogActions>
          <Button onClick={onMarchModeChangeConfirmCancel}>キャンセル</Button>
          <Button onClick={onMarchModeChangeConfirmOK} color='primary'>OK</Button>
        </DialogActions>
      </Dialog>
      {/** 転職March #75621 END */}
    </div>
  )
}

export default TopBar
